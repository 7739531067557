import myhttp from './http'
// 登录
export function login(params) {
    return myhttp.post('/back/login', params)
}
//校验token

export function isTok() {
    return myhttp({
        url: '/user/isTok',
        method: "GET",
    });
}

//修改密码
export function updatePassword(params) {
    return myhttp.post(
        '/Login/updatePassword',
        params
    );
}

//校验旧密码
export function OldPasswordCheck(params) {
    return myhttp.post(
        '/Login/OldPasswordCheck',
        params
    );
}

//获取权限列表
export function getPermissionsList() {
    return myhttp({
        url: '/per/selectPermissionsInfo',
        method: "GET",
    });
}

//查询角色类型
export function getTypeList() {
    return myhttp({
        url: '/types/selectTypesBg',
        method: "POST",
    });
}


//一个用户的权限变更
export function updateTypes(params) {
    return myhttp.post(
        '/types/updateTypes',
        params
    );
}


//删除角色类型
export function deleteTypes(id) {
    return myhttp({
        url:'/types/deleteTypes',
        method: "POST",
        params: { id }
    });
}

//添加角色类型
export function insertTypes(params) {
    return myhttp.post(
        '/types/insertTypes',
        params
    );
}

