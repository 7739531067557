<template>
  <div class="system_warp">
    <el-button style="margin-bottom: 10px" @click="handleAdd"
      >添加用户</el-button
    >

    <el-table :data="tableData" stripe height="590" style="width: 100%">
      <el-table-column prop="account" label="用户名"> </el-table-column>
      <el-table-column prop="username" label="姓名"> </el-table-column>
      <!-- <el-table-column prop="phone" label="手机号"> </el-table-column> -->
      <el-table-column
        prop="procuratorate.procuratoratename"
        label="所属检察院"
      >
      </el-table-column>
      <el-table-column prop="types.typesname" label="角色类型">
      </el-table-column>
      <el-table-column label="操作" class="operate" min-width="100px">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row, scope.row.id)"
            >编辑</el-button
          >
          <el-button size="mini" @click="handleRemove(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="用户账号：" label-width="100px">
          <el-input
            :disabled="currentValue.account"
            v-model="form.account"
            autocomplete="off"
            placeholder="请输入信息"
          />
        </el-form-item>
        <el-form-item v-if="!showPassword" label="密码：" label-width="100px">
          <el-input
            v-model="form.password"
            placeholder="请输入信息"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="姓名：" label-width="100px">
          <el-input
            v-model="form.username"
            placeholder="请输入信息"
            autocomplete="off"
          />
        </el-form-item>
         <el-form-item label="角色类型：" label-width="100px">
          <el-select
            v-model="form.typeid"
            placeholder="请选择角色类型"
            style="width: 100%"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :value="item.id"
              :label="item.typesname"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属检察院：" label-width="100px">
          <el-select
            v-model="form.procuratorateid"
            placeholder="请选择检察院名称"
            style="width: 100%"
            :disabled="disabled"
          >
            <el-option
              v-for="item in proList"
              :key="item.id"
              :value="item.id"
              :label="item.procuratoratename"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleAddClick">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import md5 from "md5";
import { Message } from "element-ui";
import {
  userList,
  registered,
  getProInfoList,
  updataUserById,
  deleteUserById,
  selectUserById
} from "@/api/system";
import {
  getTypeList
} from "@/api/api";
export default {
  name: "xxx",
  components: {},
  data() {
    return {
      perList: [],
      currentValue: {},
      dialogFormVisible: false,
      dialogVisible: false,
      form: {},
      tableData: [],
      proList: [],
      typeList: [],
      showPassword: false,
      currentId: 0,
      disabled: false,
      id: "",
      title:""
    };
  },
  mounted() {
    this._getProInfoList();
    this._getTypeList();
    // this._getPermissionsList();
    this.perList = JSON.parse(window.localStorage.getItem("permissionidList"));
    console.log(this.perList);
    this.typeid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).types.id;
    if( this.typeid == 6){
      this.procuratorateid = -1;
    }else{
      this.disabled = true;
      this.procuratorateid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).procuratorate.id;
    }
    this._userList();
    this.id = JSON.parse(window.localStorage.getItem("tltz_userinfo")).id;
    console.log(this.id);
  },
  methods: {
    //获取角色类型
    _getTypeList() {
      getTypeList().then((data) => {
        this.typeList = data.data.list;
      });
    },
    _userList() {
      userList(this.procuratorateid).then((data) => {
        console.log(data.data.list);
        this.tableData = data.data.list;
      });
    },
    //获取检察院列表
    _getProInfoList() {
      getProInfoList().then((data) => {
        this.proList = data.data.list;
      });
    },
    handleAdd() {
      let res = this.perList.filter((val) => val == 79);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');

      }else{
      this.dialogFormVisible = true;
      this.currentId = 0;
      this.form = {};
       console.log(this.procuratorateid)
      if( this.procuratorateid == -1 ){
        console.log(111)
      }else{
        this.form.procuratorateid = this.procuratorateid;
        this.disabled = true;
        

        // this.form.procuratorateid = null;
      }
      this.showPassword = false;
      this.title = "添加用户"
      this.currentValue.account = false;
      }
    },
    handleEdit(value, id) {
      let res = this.perList.filter((val) => val == 80);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');

      }else{
      selectUserById(id).then((data) => {
        console.log(data.data)
        data.data.typeid = data.data.types.id;
        data.data.procuratorateid = data.data.procuratorate.id;
        this.form = data.data;
      });
      this.currentId = id;
      this.title = "编辑用户"
      console.log(this.form);
      this.showPassword = true;
      this.currentValue.account = true;
      this.dialogFormVisible = true;

      }
    },
    handleRemove(id) {
      let res = this.perList.filter((val) => val == 81);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');

      }else{
      this.$confirm("是否删除该用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteUserById(id).then(async (data) => {
            if (data.code !== 200) {
              Message.error("操作失败！");
              return;
            }
            this.dialogFormVisible = false;
            Message.success("提交成功！");
            await this._userList();
          });
        })
        .catch(() => {
          Message.info("取消删除！");
        });
      }
    },
    handleAddClick() {
      let { account } = this.form;
      let { username } = this.form;
      let { procuratorateid } = this.form;
      let { typeid } = this.form;
      let id = this.currentId;
      console.log(id);
      if (!id) {
        let password = md5(this.form.password);
        if (!account || !username || !procuratorateid || !password || !typeid) {
          Message.error("请检查信息填写是否完整！");
          return;
        }
        const params = {
          account, password, procuratorateid, username, typeid
        }
        registered(params).then(async (data) => {
          if (data.code !== 200) {
            Message.error("操作失败！");
            return;
          }
          this.dialogFormVisible = false;
          Message.success("提交成功！");
          await this._userList();
        });
      } else {
        const params = {
          id,account, procuratorateid, username, typeid
        }
        updataUserById(params).then(async (data) => {
          if (data.code !== 200) {
            Message.error("操作失败！");
            return;
          }
          this.dialogFormVisible = false;
          Message.success("提交成功！");
          await this._userList();
        });
      }
    },
  },
};
</script>
<style lang='less' >
.system_warp {
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: 92%;
  background: #fff;
  margin-top: 16px;
  padding: 20px 13px 0 13px;
  .cell {
    text-align: center;
  }
}
</style>